import React from "react"
import { IAppContext } from "../definitions/interfaces"
import queryString from "query-string"
import _ from "lodash"
import auth from "@utils/auth"

const initConfig: IAppContext = {
  user: null,
  apiUrl: process.env.API_URL,
  testString: "Test",
  token: "",
  screenSize: {},
  weatherData: null,
  lastReadTimes: [],
  userPreferences: {},
}

export const AppConfig: IAppContext = { ...initConfig }

export const UpdateConfig = (key: keyof IAppContext, value: any) => {
  AppConfig[key] = value
}

export const GetConfig = (): IAppContext => {
  //config is initialized before the app is bootstrapped in App.tsx,
  //we are relying on that to insure we have the correct API config
  return AppConfig
}

export const InitializeConfig = async (): Promise<IAppContext> => {
  //query string config. if there is a key in the QS that matches
  //our build context then we can override it with that value.
  if (process.env.apiUrl) {
    UpdateConfig("apiUrl", process.env.apiUrl)
  }

  if (auth.getUserInfo() !== null && auth.getToken() !== null) {
    UpdateConfig("user", auth.getUserInfo())
    UpdateConfig("token", auth.getToken())
  }

  let assignValueToAppConfig = (key: keyof IAppContext, value: any) => {
    if (_.isEmpty(value)) {
      AppConfig[key] = value
    } else {
      let _value = Number(value)
      AppConfig[key] = _.isNaN(_value) ? value : _value
    }
  }

  let doesConfigKeyExist = (key: any) => {
    // we will ignore all API urls given in query strings
    if (key === "apiUrl" || key === "baseUrl") {
      return false
    }

    return _.includes(_.keys(AppConfig), key)
  }

  if (window) {
    const QueryString: any = queryString.parse(window.location.search)
    const ParentQueryString: any = queryString.parse(
      window.parent.location.search
    )

    for (const queryKey in QueryString) {
      let queryValue = QueryString[queryKey]
      // if running in production find the json file relative to iframe

      if (doesConfigKeyExist(queryKey)) {
        assignValueToAppConfig(queryKey as keyof IAppContext, queryValue)
      }

      //we have to put legacy params in here until we get a
      //chance to clean up differences in keys. e.g. apiUrl => baseUrl
    }

    for (const queryKey in ParentQueryString) {
      let queryValue = ParentQueryString[queryKey]
      if (doesConfigKeyExist(queryKey)) {
        assignValueToAppConfig(queryKey as keyof IAppContext, queryValue)
      }
    }
  }

  return AppConfig
}

export const AppContext = React.createContext(AppConfig)
